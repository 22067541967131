.wiki-page {
  line-height: 2rem;

  &-header {
    margin: 0 0 1rem 0;

    &--sub {
      display: block;
      font-size: 1.25rem; 
      font-weight: 200;
      margin-top: .6rem;
    }
  }

  &-footer {
    border-top: 1px solid #ddd;
    padding-top: .25rem;
    font-size: 75%;
    line-height: 1rem;
    color: #aaa;
  }
}