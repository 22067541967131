w.layout {
  height: 100%;
  width: 100%;
  display: flex;
  overflow: hidden;
}

.navigation-container {
  position: relative;
  z-index: 1;

  border-right: 1px solid #ccc;
  height: 100%;
}

.content-container {
  position: relative;
  z-index: 0;

  flex: 1 0 0;
  height: 100dvh;
  width: 100vw;
  display: flex;
  overflow: hidden;
}

.navigation {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 65px;

  hr {
    margin: 0 0 .5em 0;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .navigation-link {
    display: flex;
    flex-direction: column;
    padding: 10px;
    text-decoration: none;
    color: #3f3f3f;
    font-size: .75em;
    text-align: center;
    margin-bottom: .5em;

    &.active {
      color: #916d2d;
    }
  }

  .navigation-logo {
    padding: 10px;
    height: 65px;
    width: 100%;
    object-fit: contain;
    margin: .75em 0;
  }

  .navigation-icon {
    font-size: 1.75em;
    margin-bottom: .25em;
  }
}