@import "../../styles/variables.scss";

.page-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 320px;
}

.page-list-scroll-container {
  flex: 1 0 0;
  overflow: auto;
}

.page-list-inner {
  margin: 0;
  padding: .25rem;
  list-style: none;

  > li {
    margin-bottom: .25rem;
  }
}

.letter {
  position: sticky;
  top: 0;
  text-indent: .75rem;
  font-weight: bold;
  background-color: rgba(255,255,255,0.2);
  backdrop-filter: blur(2px);
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}

.btn {
  --bs-btn-hover-bg: rgba(255, 255, 255, 0.9);
  --bs-btn-hover-border-color: rgba(0, 0, 0, 0.4);

  text-align: left;

  overflow: hidden;
  text-overflow: ellipsis;
}