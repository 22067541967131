.wiki {
  flex: 1;
  width: 100%;
  margin: 0 auto;
  display: flex;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.75);
  background: #fff;

  h1 {
    overflow-wrap: break-word;
  }

  h6 {
    margin: 0;
  }

  &-main {
    flex: 1 0 0;
    overflow: auto;
    line-height: 1.75rem;
    padding: 1rem;
    max-width: 100vw;

    @apply p-1 md:p-4 prose-lg mt-16 sm:mt-8;

    img, figure[class*="image"] {
      @apply m-0 mt-2 rounded-md shadow;

      &[class*="image--left"], &[class*="align-left"] {
        @apply float-left mr-4;
      }

      &[class*="image--right"], &[class*="align-right"] {
        @apply float-right ml-4;
      }
    }

    figure[class*="image"] {
      @apply shadow px-2;

      img {
        @apply shadow-none;
      }

    }
  }

  &-nav {
    background: #F8FAFC;
    border-right: 1px solid #E2E8F0;

    @apply flex flex-col absolute z-10 w-full h-full border-0 translate-x-0 transition-[transform] leading-6;
    @apply md:border md:relative md:w-80;

    &.is-collapsed {
      @apply -translate-x-full md:translate-x-0;
    }

    &__header {
      @apply flex gap-4 text-center py-1 px-2 justify-between items-center border-b leading-4 sm:justify-center;
      @apply md:px-2 sm:py-2;
    }

    &__body {
      flex: 1 1 0;
      font-size: 90%;
      width: 100%;
      position: relative;
      overflow: hidden;
    }

    &__footer {
      bottom: 0;
      height: 47px;
      color: rgba(0, 0, 0, 0.4);
      font-size: 75%;
      text-align: center;
      padding: .75rem 1.5rem;
      background-color: #fff;
      border-top: 1px solid #f0e9e9;
    }
  }

  &-floatinglink {
    $width: 38px;
    $color: #bbbbbd;

    display: flex;
    justify-content: center;
    align-items: center;
    color: $color;
    font-size: 1.2rem;
    text-decoration: none;
    position: absolute;
    z-index: 10;
    top: 16px;
    right: -$width/2;
    width: $width;
    aspect-ratio: 1;
    background-color: #fff;
    border-radius: 100%;
    border: 1px solid $color;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, .25);

    @apply hidden md:flex;

    &:hover {
      border-color: #868688;
      color: #868688;
    }
  }
}

.btn-expand {
  background-color: #fff;
  border-radius: 100%;
  border: 1px solid #bbbbbd;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, .25);
  aspect-ratio: 1;
  width: 42px;
  color: #444444;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  @apply inline-flex md:hidden;
}

.btn-close {
  background-color: #fff;
  border-radius: 100%;
  border: 1px solid #bbbbbd;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, .25);
  aspect-ratio: 1;
  width: 42px;
  color: #444444;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  @apply inline-flex md:hidden;
}